import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { formatDistance } from "date-fns";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  CardContent,
  Container,
  CardHeader,
  Pagination,
  Stack,
  Button,
} from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import "instantsearch.css/themes/reset.css";

const JobsPerPage = 21;
export default function JobsList() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [keyId, setKeyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const FetchJobs = () => {
    const q = query(
      collection(db, "jobs"),
      where("hireimm", "==", true),
      where("status", "==", true),
      orderBy("createDate", "desc"),
      limit(JobsPerPage)
    );
    getDocs(q).then((jobs) => {
      jobs.forEach((job) => {
        setJobs((old) => [...old, { id: job.id, ...job.data() }]);
        setKeyId(job.data().createDate);
      });
    });
    setLoading(true);
  };
  const FetchOtherJobs = () => {
    const q = query(
      collection(db, "jobs"),
      where("hireimm", "==", true),
      where("status", "==", true),
      orderBy("createDate", "desc"),
      startAfter(keyId),
      limit(JobsPerPage)
    );
    getDocs(q).then((jobs) => {
      jobs.forEach((job) => {
        setJobs((old) => [...old, { id: job.id, ...job.data() }]);
        setKeyId(job.data().createDate);
      });
    });
    setLoading(true);
  };
  const pageTopRef = useRef(null);

  useEffect(() => {
    FetchJobs();
  }, []);

  return loading ? (
    <Container sx={{ justifyContent: "center", alignItems: "center" }}>
      <Typography
        variant="h4"
        color="text.primary"
        align={"center"}
        gutterBottom
        sx={{
          fontWeight: 700,
        }}
      >
        Job opportunities
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        sx={{ fontWeight: 200 }}
        align={"center"}
        paddingBottom={2}
      >
        Here are the latest jobs that you may be interested in
      </Typography>
      <Grid container spacing={4}>
        {jobs.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                onClick={() => navigate(`/jobs/${item.id}`)}
                width={1}
                height={1}
                data-aos={"fade-up"}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
                flexDirection={"column"}
                display={"flex"}
                sx={{
                  "&:hover": {
                    borderRight: `${theme.spacing(1 / 2)} solid primary`,
                  },
                }}
              >
                <CardHeader
                  component={Box}
                  subheader={
                    item.category ? (
                      <Box padding={1 / 2} bgcolor={"#C02D00"} borderRadius={2}>
                        <Typography
                          variant={"caption"}
                          align={"center"}
                          sx={{ color: theme.palette.common.white }}
                        >
                          {item.category}
                        </Typography>
                      </Box>
                    ) : null
                  }
                ></CardHeader>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    {item.title}
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} marginY={1}>
                    <Box
                      component={"svg"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={16}
                      height={16}
                      marginRight={1}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </Box>
                    <Typography variant={"subtitle2"} color="text.secondary">
                      {item.location && item.location !== "null"
                        ? item.location
                        : "Canada"}
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box
                      component={"svg"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={16}
                      height={16}
                      marginRight={1}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </Box>
                    <Typography variant={"subtitle2"} color="text.secondary">
                      {formatDistance(
                        new Date(item.createDate.toDate()),
                        new Date(),
                        { addSuffix: true }
                      )}
                    </Typography>
                  </Box>
                </CardContent>
              </Box>
            </Box>
          </Grid>
        ))}

        <Button
          variant="contained"
          sx={{ m: 2 }}
          onClick={() => {
            FetchOtherJobs();
          }}
        >
          Load more
        </Button>
      </Grid>
    </Container>
  ) : (
    <></>
  );
}
