import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "../../assets/styles/Container";
import Application from "./Application";

const SubmitResume = () => {
  return (
    <Container>
      <Box>
        <Typography
          variant="h4"
          color="text.primary"
          align={"center"}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Here is where you can start your career
        </Typography>
        <Typography variant="h6" component="p" color="text.secondary" sx={{ fontWeight: 400 }} align={"center"}>
          We will send your resume to approved employers after you submit it
        </Typography>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems={{ xs: "stretched", sm: "flex-start" }} justifyContent={"center"} marginTop={4}>
          <Application />
        </Box>
      </Box>
    </Container>
  );
};

export default SubmitResume;
