import {
  Box,
  Button,
  Container,
  Link,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Moment from "react-moment";
import Application from "./Application";
import { useReactToPrint } from "react-to-print";
import NotFound from "./main/404";

export default function Job() {
  const { slug } = useParams();
  const [job, setJob] = useState({});
  const [loading, setloading] = useState(false);
  const GetJob = async () => {
    const docRef = doc(db, "jobs", slug);
    const dataRef = await getDoc(docRef);
    setJob({ id: dataRef.id, ...dataRef.data() });

    setloading(true);
  };

  useEffect(() => {
    GetJob();
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      const tableStat = componentRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      const header =
        `<img src="${process.env.REACT_APP_LOGO}" alt="" class="page-header"/>` +
        `<img src="${process.env.REACT_APP_LOGO}" alt="" class="watermark"/>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  const getPageMargins = () => {
    return `@page { margin:  100px 100px 100px 100px !important; }
    @media screen {
      
    }
    @media print {
      .hidenClassName {
        visibility: hidden;
      }
      .watermark {
        position: fixed;
        top: 50vh;
        z-index: 9;
        width: 50vw;
        page-break-after: always;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .1;
      }
      div.page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 50px;
        font-size: 15px;
        
        opacity: 0.5;
        page-break-after: always;
      }
      div.page-header {
        position: fixed;
        z-index: 9;
        top: 0;
        width: 100%;
        height: 50px;
        font-size: 15px;
        color: #fff;
        opacity: 0.5;
        page-break-after: always;
      }
    }`;
  };

  return loading ? (
    <>
      {job.hireimm === true ? (
        <Container>
          <style>{getPageMargins()}</style>
          <div ref={componentRef}>
            <h1>{job.title}</h1>
            <h2>{job.companyName}</h2>
            <h4>{job.location}</h4>
            <Table
              sx={{ minWidth: 650, backgroundColor: "#E0F2E9" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Date Posted:
                  </TableCell>
                  <TableCell align="left">
                    <Moment unix format="YYYY MMM DD">
                      {job.createDate.seconds}
                    </Moment>
                  </TableCell>
                  {job.expireDate.seconds ? (
                    <>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        Expiration date:
                      </TableCell>
                      <TableCell align="left">
                        <Moment unix format="YYYY MMM DD">
                          {job.expireDate.seconds}
                        </Moment>
                      </TableCell>
                      <TableCell className="hidenClassName">
                        <Link href="#app">
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            Apply now
                          </Button>
                        </Link>
                      </TableCell>
                      <TableCell className="hidenClassName">
                        <Button variant="outlined" onClick={handlePrint}>
                          print
                        </Button>
                      </TableCell>
                    </>
                  ) : null}
                </TableRow>
              </TableHead>
            </Table>
            {job.expireDate.seconds > Math.floor(Date.now() / 1000) ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: job.description,
                }}
              />
            ) : (
              <h2>It is no longer possible to apply for this job</h2>
            )}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Vacancy
                  </TableCell>
                  <TableCell align="left">{job.vacancy}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Category
                  </TableCell>
                  <TableCell align="left">{job.category}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Location
                  </TableCell>
                  <TableCell align="left">{job.location}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Education
                  </TableCell>
                  <TableCell align="left">{job.education}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Experience
                  </TableCell>
                  <TableCell align="left">{job.experience}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Hours per week
                  </TableCell>
                  <TableCell align="left">{job.hoursPerWeek}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Rate/h (minimum)
                  </TableCell>
                  <TableCell align="left">{job.ratePerHourMin}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Rate/h (maximum)
                  </TableCell>
                  <TableCell align="left">{job.ratePerHourMax}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Salary Minimum
                  </TableCell>
                  <TableCell align="left">{job.salaryMin}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Salary Maximum
                  </TableCell>
                  <TableCell align="left">{job.salaryMax}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Languages preferred
                  </TableCell>
                  <TableCell align="left">{job.languages}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Type
                  </TableCell>
                  <TableCell align="left">{job.type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Listing ID
                  </TableCell>
                  <TableCell align="left">{job.id}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    How to Apply
                  </TableCell>
                  <TableCell align="left">{job.externalLink}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <p>
              <br />
            </p>
          </div>
          <Box bgcolor={"alternate.main"} marginY={2}>
            <Container id="app">
              <Box marginY={8}>
                <Divider>
                  <Typography
                    variant={"h4"}
                    sx={{ fontWeight: 700 }}
                    gutterBottom
                    align={"center"}
                  >
                    Apply for this job
                  </Typography>
                </Divider>
                <Typography color="text.secondary" align={"center"}>
                  If you are interested in this position, you can send your
                  resume directly to the employer.
                </Typography>
              </Box>
              <Application id={slug} />
            </Container>
          </Box>
        </Container>
      ) : (
        <NotFound />
      )}
    </>
  ) : (
    <Container>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Container>
  );
}
