import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import "react-quill/dist/quill.snow.css";
import Container from "../../assets/styles/Container";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export default function Employer() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const navigate = useNavigate();
  return (
    <Container>
      <Box marginY={8}>
        <Typography variant={"h4"} gutterBottom align={"center"} sx={{ fontWeight: 700 }}>
          Your company no longer faces a staff shortage since we are here
        </Typography>
        <Typography variant={"h6"} component={"p"} color={"text.secondary"} align={"center"}>
          If you are a company looking to hire temporary workers or foreign workers,
          <br /> please fill out the form below to evaluate your company for free.
          <br /> We are always happy to help Canadian businesses fill their labour shortages with qualified staff.
        </Typography>
      </Box>
      <Box margin={"0 auto"}>
        <Box
          sx={{
            "& .MuiOutlinedInput-root.MuiInputBase-multiline": {
              padding: 0,
            },
            "& .MuiOutlinedInput-input": {
              background: theme.palette.background.paper,
              padding: 2,
            },
          }}
        >
          <Formik
            initialValues={{ email: "" }}
            enableReinitialize
            onSubmit={async (values) => {
              await addDoc(collection(db, "employers"), values);
              toast.success("Thank you for submitting the form", {});
              navigate("/");
            }}
          >
            {({ values, dirty, handleChange }) => {
              return (
                <Form>
                  <Grid container spacing={isMd ? 4 : 2}>
                    <Grid item xs={12} sm={6} data-aos="fade-up">
                      <TextField onChange={handleChange} label="First Name" variant="outlined" name="firstName" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6} data-aos="fade-up">
                      <TextField onChange={handleChange} label="Last Name" variant="outlined" name="lastName" fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} data-aos="fade-up">
                      <TextField onChange={handleChange} label="Email" variant="outlined" name="email" fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} data-aos="fade-up">
                      <TextField onChange={handleChange} label="Phone Number" variant="outlined" name="phone" fullWidth />
                    </Grid>
                    <Grid item xs={12} data-aos="fade-up">
                      <Typography>Describe your company in a few words</Typography>
                      <TextField onChange={handleChange} multiline rows={5} variant="outlined" name="description" fullWidth />
                    </Grid>

                    <Grid item justifyContent="center" xs={12} sm={6}>
                      <Typography align={"left"} variant={"caption"} color="text.secondary" component={"p"}>
                        Hireimm experts will contact you upon request
                      </Typography>
                      <Button variant="contained" type="submit" color="primary" disabled={!dirty}>
                        Request now
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Container>
  );
}
