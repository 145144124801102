import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Hits, Configure, connectSearchBox, connectPagination } from "react-instantsearch-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Button, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Hit from "./Hit";
import "instantsearch.css/themes/reset.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export default function SearchJob() {
  const theme = useTheme();
  const searchClient = algoliasearch("SKKCC8UPXP", "5e55f2bb07b6d7b2cf97520ac06f34ea");
  const [loadPage, setLoadPage] = useState(10);
  let cat = [];
  Hit.propTypes = {
    hit: PropTypes.object.isRequired,
  };

  console.log("cat", cat);

  async function Category() {
    const docRef = collection(db, "categories");
    await getDocs(docRef).then((categories) => {
      categories.forEach((category) => {
        cat.push(category.data());
      });
    });
  }
  useEffect(() => {
    Category();
  }, []);
  const SearchBox = ({ currentRefinement, refine }) => (
    <FormControl fullWidth variant="outlined">
      <OutlinedInput
        type="search"
        placeholder="Job title, NOC, ..."
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
        }}
        startAdornment={
          <InputAdornment position="start">
            <Box component={"svg"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={24} height={24}>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </Box>
          </InputAdornment>
        }
      />
    </FormControl>
  );

  const CustomSearchBox = connectSearchBox(SearchBox);

  return (
    <Box>
      <InstantSearch searchClient={searchClient} indexName="jobs">
        <Box>
          <CustomSearchBox />
        </Box>
        <Configure filters={`status:true AND hireimm:true `} hitsPerPage={loadPage} />
      </InstantSearch>
    </Box>
  );
}
