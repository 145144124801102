/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { colors } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const mock = [
  {
    title: "Assess company",
    subtitle: "In order to hire foreign employees, we will first examine your company.",
    color: colors.yellow[700],
    icon: (
      <svg height={24} width={24} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
      </svg>
    ),
  },
  {
    title: "Select candidates",
    subtitle: "Your vacant positions will be filled by suitable candidates.",
    color: colors.indigo[700],
    icon: (
      <svg height={24} width={24} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    ),
  },
  {
    title: "Paperwork",
    subtitle: "We assist you in all legal steps needed to obtain Labour Market Impact Assessment (LMIA) and apply a work visa for candidates",
    color: colors.blue[700],
    icon: (
      <svg height={24} width={24} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
  },
];

const AdvantagesEmployer = () => {
  return (
    <Box bgcolor={"background.paper"} padding={4} marginY={8} borderRadius={5}>
      <Box marginY={8}>
        <Typography variant={"h4"} gutterBottom align={"center"} sx={{ fontWeight: 700 }}>
          Hireimm benefits for employers
        </Typography>
        <Typography variant={"h6"} component={"p"} color={"text.secondary"} align={"center"}>
          Our advisors guide you through the process of obtaining a <strong>LMIA</strong>,<br /> processing an immigration application, and explaining your rights and risks along the way.
        </Typography>
        <Box marginTop={3} display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            href="/employer"
            endIcon={
              <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            }
          >
            Start hiring
          </Button>
        </Box>
      </Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box display={"flex"} flexDirection={"column"} data-aos={"fade-up"} data-aos-delay={i * 100} data-aos-offset={100} data-aos-duration={600}>
                <Box component={Avatar} height={80} marginBottom={2} bgcolor={"transparent"} color={item.color} variant={"square"} paddingX={0} borderBottom={`2px solid ${item.color}`}>
                  {item.icon}
                </Box>
                <Typography variant={"h5"} gutterBottom sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AdvantagesEmployer;
