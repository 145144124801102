const type = [
  { name: "Freelance", label: "Freelance" },
  { name: "Full Time", label: "Full Time" },
  { name: "Part Time", label: "Part Time" },
  { name: "Internship", label: "Internship" },
  { name: "Temporary", label: "Temporary" },
];
const Languages = [
  { name: "English", label: "English" },
  { name: "French", label: "French" },
  { name: "English & French", label: "English & French" },
];

const Experience = [
  { name: "Under 3 Months", label: "Under 3 Months" },
  { name: "3 to 6 Months", label: "3 to 6 Months" },
  { name: "1 Year", label: "1 Year" },
  { name: "2 Years", label: "2 Years" },
  { name: "3 Years", label: "3 Years" },
  { name: "4 Years", label: "4 Years" },
  { name: "5 Years and more", label: "5 Years and more" },
];

const PostData = [
  { name: "title", label: "Job Title", format: "text" },
  { name: "vacancy", label: "Vacancy", format: "number" },
  { name: "location", label: "Location", format: "text" },
  { name: "education", label: "Education", format: "text" },
  { name: "experience", label: "Experience", format: "select", options: Experience },
  { name: "email", label: "Application email", format: "text" },
  { name: "externalLink", label: "External 'Apply for Job' link", format: "text" },
  { name: "hoursPerWeek", label: "Hours per week", format: "number" },
  { name: "ratePerHourMin", label: "Rate/h (minimum)", format: "text" },
  { name: "ratePerHourMax", label: "Rate/h (maximum)", format: "text" },
  { name: "salaryMin", label: "Salary Min", format: "text" },
  { name: "salaryMax", label: "Salary Max", format: "text" },
  { name: "type", label: "Type", format: "select", options: type },
  { name: "languages", label: "Languages", format: "select", options: Languages },
];

export default PostData;
