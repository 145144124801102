import { Field, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db, ServerTime } from "../../firebase";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormElements } from "../../assets/styles/FormElements";
import PostData from "./PostData";
import { useAuth } from "../contexts/AuthContext";
import { Container, FormControlLabel, Typography } from "@mui/material";
import { Switch } from "formik-material-ui";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";

let cat = [];
let com = [];

export default function EditJob() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [description, setDescription] = useState(state.description);
  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], ["bold", "italic", "underline", "strike", "link"], [{ align: [] }], [{ color: [] }, { background: [] }], ["clean"]],
  };

  async function Category() {
    const docRef = collection(db, "categories");
    await getDocs(docRef).then((categories) => {
      categories.forEach((category) => {
        cat.push(category.data());
      });
    });
  }

  async function Companies() {
    const docRef = collection(db, "companies");
    const q = query(docRef, where("uid", "==", currentUser.uid));
    await getDocs(q).then((companies) => {
      companies.forEach((company) => {
        com.push({ name: company.id, label: company.data().title });
      });
    });
  }

  useEffect(() => {
    Category();
    Companies();
  }, []);

  return (
    <Container>
      <Formik
        initialValues={state}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          if (!values.location) {
            values.location = "Canada";
          }
          values.description = description;
          delete values.createDate;
          delete values.expireDate;
          delete values.websites;
          const docRef = doc(db, "jobs", values.id);
          await setDoc(docRef, { ...values, editDate: ServerTime }, { merge: true }).then(() => {
            navigate("/dashboard");
          });
          resetForm();
        }}
      >
        {({ dirty, values }) => (
          <Form>
            <Box paddingY={{ xs: 2, sm: 1, md: 1 }}>
              <Grid container spacing={2}>
                {PostData.map((data, i) => {
                  return <FormElements type={data.format} Field={Field} label={data.label} name={data.name} options={data.options} rows={data.rows} />;
                })}
                <Grid item xs={12}>
                  <Typography marginY={2}>Description</Typography>
                  <ReactQuill theme="snow" value={description} modules={modules} onChange={setDescription} />
                </Grid>
                <FormElements type="select" Field={Field} label="Category" name="category" options={cat} />
                <FormElements type="select" Field={Field} label="Company" name="company" options={com} />
                <Box margin={1}>
                  <Typography variant={"h5"}>Target Foreign Worker (For FREE)</Typography>
                  <Typography color="text.secondary" align={"left"}>
                    If you select this option, your ad will appear on <a href="https://hireimm.com">Hireimm.com</a> for free. Foreign workers will be the target audience for this website.
                  </Typography>
                  <FormControlLabel control={<Field component={Switch} type="checkbox" name="hireimm" />} label="Post on HireImm" />
                </Box>

                <Grid item container xs={12}>
                  <Button size={"large"} variant={"contained"} type={"submit"}>
                    Edit job
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
