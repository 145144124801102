import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Landing from "./components/pages/main/Landing";
import PostJob from "./components/pages/PostJob";
import Job from "./components/pages/Job";
import EmployerDashboard from "./components/pages/EmployerDashboard";
import Packages from "./components/pages/Packages";
import { useAuth } from "./components/contexts/AuthContext";
import SignUpForm from "./components/authentication/SignupForm";
import SignInForm from "./components/authentication/SignInForm";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import SubmitResume from "./components/pages/SubmitResume";
import Layout from "./assets/styles/Layout";
import AddCompany from "./components/company/AddCompany";
import EditJob from "./components/jobs/EditJob";
import ForgotPass from "./components/authentication/ForgotPass";
import NotFound from "./components/pages/main/404";
import Employer from "./components/employer/Employer";
import Jobs from "./components/pages/jobs";

function App() {
  const { currentUser } = useAuth();

  const PrivateRoute = () => {
    const location = useLocation();
    return currentUser ? <Outlet /> : <Navigate to={"/signin"} replace state={{ from: location }} />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="signin" element={<SignInForm />} />
        <Route path="forgot" element={<ForgotPass />} />
        <Route path="signup" element={<SignUpForm />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Landing />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="resume" element={<SubmitResume />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="packages" element={<Packages />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="jobs/:slug" element={<Job />} />
          <Route path="employer" element={<Employer />} />
          <Route element={<PrivateRoute />}>
            <Route path="post" element={<PostJob />} />
            <Route path="edit" element={<EditJob />} />
            <Route path="company" element={<AddCompany />} />
            <Route path="dashboard" element={<EmployerDashboard />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
