import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "../../assets/styles/Container";

const AboutUs = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        <Grid container spacing={4} direction={isMd ? "row" : "column"}>
          <Grid item container alignItems={"center"} xs={12} md={6}>
            <Box>
              <Typography variant={"h4"} gutterBottom sx={{ fontWeight: 700 }}>
                About Us
              </Typography>
              <Typography component={"p"}>
                HireImm Enterprise LTD. is a licensed recruitment agency with more than 2 years of experience.
                <br />
                The company helps Canadian employers find temporary staff and bring qualified foreign workers to fill their needs.
                <br />
                We are an authorized recruitment agency in the province of British Columbia and are based in Vancouver. Our services are available in English, French, Chinese, and Persian.
              </Typography>
              <Typography component={"a"} href={"https://services.labour.gov.bc.ca/licensing/TFW_GenerateIssuance?id=a0b5o000000SMwLAAW"}>
                Our License
              </Typography>
            </Box>
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" xs={12} md={6}>
            <Box maxWidth={500} width={1}>
              <Box
                component={"img"}
                src={"https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration1.svg"}
                width={1}
                height={1}
                sx={{
                  filter: theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutUs;
