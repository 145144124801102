import React from "react";
import Container from "../../assets/styles/Container";
import JobsList from "./JobsList";

export default function Jobs() {
  return (
    <Container>
      <JobsList />
    </Container>
  );
}
