import { Field, Formik, Form } from "formik";
import React from "react";
import { addDoc, collection } from "firebase/firestore";
import { db, ServerTime } from "../../firebase";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormElements } from "../../assets/styles/FormElements";
import { useAuth } from "../contexts/AuthContext";
import { Container } from "@mui/material";
import CompaniesList from "./CompaniesList";

let initialValues = {
  title: "",
};

const PostData = [
  { name: "title", label: "Company Title", format: "text" },
  { name: "description", label: "Company Description", format: "textArea", rows: 5 },
  { name: "location", label: "Location", format: "text" },
  { name: "email", label: "Email", format: "text" },
  { name: "address", label: "Address", format: "text" },
  { name: "phone", label: "Phone", format: "text" },
  { name: "linkedin", label: "Linkedin", format: "text" },
];
export default function AddCompany() {
  const { currentUser } = useAuth();

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          const docRef = collection(db, "companies");
          addDoc(docRef, { ...values, uid: currentUser.uid, createDate: ServerTime });
          resetForm();
        }}
      >
        {({ dirty }) => (
          <Form>
            <Box paddingY={{ xs: 2, sm: 1, md: 1 }}>
              <Grid container spacing={2}>
                {PostData.map((data, i) => {
                  return <FormElements key={i} type={data.format} Field={Field} label={data.label} name={data.name} options={data.options} rows={data.rows} />;
                })}
                <Grid item container xs={12}>
                  <Button size={"large"} variant={"contained"} type={"submit"} disabled={!dirty}>
                    Add company
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
      <CompaniesList />
    </Container>
  );
}
