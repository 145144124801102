import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import canada from "./assets/canada.png";
import britishColumbia from "./assets/britishColumbia.png";
import { Link } from "@mui/material";
const Partners = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box display="flex" flexWrap="wrap" justifyContent={"center"}>
        {[
          { logo: canada, link: "https://www.canada.ca/en/employment-social-development/services/foreign-workers.html", alt: "Goverment of Canada" },
          { logo: britishColumbia, link: "https://services.labour.gov.bc.ca/licensing/TFW_IssuancePublication", alt: "Licensed Foreign Worker Recruiters" },
        ].map((item, i) => (
          <Box maxWidth={100} marginTop={2} marginRight={4} key={i}>
            <Link href={item.link}>
              <Box
                component="img"
                width={1}
                src={item.logo}
                alt={item.alt}
                sx={{
                  filter: theme.palette.mode === "dark" ? "brightness(0) invert(0.7)" : "none",
                }}
              />
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Partners;
