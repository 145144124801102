/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import deal from "./assets/deal.gif";
const AboutCenter = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item container xs={12} md={6} data-aos={isMd ? "fade-right" : "fade-up"} alignItems={"center"}>
          <Box>
            <Typography variant={"h4"} gutterBottom sx={{ fontWeight: 700 }}>
              WHY HireImm
            </Typography>
            <Typography variant={"h6"} component={"p"} color={"text.secondary"} gutterBottom>
              HireImm Enterprise LTD. is a licensed recruitment agency that helps Canadian companies fill labour shortages with temporary and foreign staff.
              <br /> We have a wealth of experience sourcing temporary and foreign workers for Canadian companies facing labour shortages and high employee turnover.
              <br /> During the recruitment process, we focus on the employer's specific requirements. Whether they need workers for a year or more, we can help employers find the right staff.
            </Typography>
          </Box>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={6}>
          <Box maxWidth={500} width={1}>
            <Box
              component={"img"}
              src={deal}
              width={1}
              height={1}
              sx={{
                filter: theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutCenter;
