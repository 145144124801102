import React from "react";
import Container from "../../../assets/styles/Container";
import JobsList from "../JobsList";
import AboutCenter from "./AboutCenter";
import Advantages from "./Advantages";
import AdvantagesEmployer from "./AdvantagesEmployer";
import Hero from "./Hero";
import NewsLetter from "./NewsLetter";
import Partners from "./Partners";
import PromoNumbers from "./PromoNumbers";

export default function Landing() {
  return (
    <Container>
      <Hero />
      <Partners />
      <Advantages />
      <AdvantagesEmployer />
      <AboutCenter />
      <PromoNumbers />
      {/* <JobsList />
      <NewsLetter /> */}
    </Container>
  );
}
