import { Field, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs, increment, query, setDoc, where } from "firebase/firestore";
import { db, nowTimeStamp, ServerTime } from "../../firebase";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormElements } from "../../assets/styles/FormElements";
import PostData from "./PostData";
import { useAuth } from "../contexts/AuthContext";
import { Container, FormControlLabel, Skeleton, Typography } from "@mui/material";
import { Switch } from "formik-material-ui";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TransferToBuy from "./TransferToBuy";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AboutSites from "./AboutSites";
import { useNavigate } from "react-router-dom";
let initialValues = {
  title: "",
  hireimm: true,
  websites: ["youthjobshub", "gojobhunter", "newcomerneeds", "aboriginalcareer"],
  status: false,
};
let cat = [];
let com = [];
let Available = 0;
let jobPosts = 0;

const options = [
  {
    label: "Youth Jobs Hub",
    value: "youthjobshub",
    description:
      "Youth Jobs Hub is an online job portal website designed to connect Canadian youth with various employment opportunities in the country. Our website is tailored towards providing an easy-to-use platform for young individuals to search and apply for jobs that match their skill set and interests.",
  },
  {
    label: "Go Job Hunter",
    value: "gojobhunter",
    description:
      "GoJobHunter.com is a comprehensive job portal website designed to cater to the employment needs of the Canadian population. Our platform connects job seekers with a wide range of job opportunities across various industries and locations in Canada.",
  },
  {
    label: "Newcomer Needs",
    value: "newcomerneeds",
    description:
      "Newcomerneeds.com is a job portal website specifically designed for newcomers to Canada. Our website is aimed at providing job opportunities and resources to help immigrants find their footing in the Canadian job market. We recognize the unique challenges that newcomers face when looking for employment, and our goal is to help them navigate those challenges by offering them the tools they need to succeed.",
  },
  {
    label: "Aboriginal Career",
    value: "aboriginalcareer",
    description:
      "AboriginalCareer.ca is a job portal website designed to connect Aboriginal people in Canada with employment opportunities that match their skills and interests. Our platform offers a wide range of job postings, from entry-level positions to senior management roles, in various industries across Canada.",
  },
];
export default function PostJobForm() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], ["bold", "italic", "underline", "strike", "link"], [{ align: [] }], [{ color: [] }, { background: [] }], ["clean"]],
  };

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "3px solid #dadde9",
    },
  }));

  async function Category() {
    const docRef = collection(db, "categories");
    await getDocs(docRef).then((categories) => {
      categories.forEach((category) => {
        cat.push(category.data());
      });
    });
  }

  async function Companies() {
    const docRef = collection(db, "companies");
    const q = query(docRef, where("uid", "==", currentUser.uid));
    await getDocs(q).then((companies) => {
      companies.forEach((company) => {
        com.push({ name: company.id, label: company.data().title });
      });
    });
  }

  const fetchPayments = async () => {
    const docRef = collection(db, "users", currentUser.uid, "payments");
    const postRef = doc(db, "users", currentUser.uid);
    const postAvailable = await getDoc(postRef);
    jobPosts = postAvailable.data().jobPosts ? postAvailable.data().jobPosts : 0;
    await getDocs(docRef).then((payments) => {
      payments.forEach((payment) => {
        if (payment.data().status === "succeeded") {
          Available += Number(payment.data().metadata.item);
        }
      });
      setLoading(true);
    });
  };
  useEffect(() => {
    Category();
    Companies();
    fetchPayments();
  }, []);

  return loading ? (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          if (!values.location) {
            values.location = "Canada";
          }
          values.description = description;

          const docRef = collection(db, "jobs");
          const userRef = doc(db, "users", currentUser.uid);
          let time = nowTimeStamp.toDate();
          time.setDate(time.getDate() + 180);
          await addDoc(docRef, { ...values, uid: currentUser.uid, createDate: ServerTime, expireDate: time }).then(() => {
            addDoc(collection(db, "mail"), {
              to: "rezaheidari@gmail.com",
              message: {
                subject: "Go job hunter new post",
                text: "This is the plaintext section of the email body.",
                html: "This is the <code>HTML</code> section of the email body.",
              },
            });
          });
          await setDoc(userRef, { jobPosts: increment(values.websites.length), availablePosts: increment(values.websites.length * -1) }, { merge: true });
          navigate("/dashboard");
          resetForm();
        }}
      >
        {({ dirty, values }) => (
          <Form>
            {Available - jobPosts > 0 ? (
              <Box paddingY={{ xs: 3, sm: 1, md: 1 }}>
                <Grid container spacing={2}>
                  {PostData.map((data, i) => {
                    return <FormElements type={data.format} Field={Field} label={data.label} name={data.name} options={data.options} rows={data.rows} />;
                  })}
                  <Grid item xs={12}>
                    <Typography marginY={2}>Description</Typography>
                    <ReactQuill theme="snow" value={description} modules={modules} onChange={setDescription} />
                  </Grid>
                  <FormElements type="select" Field={Field} label="Category" name="category" options={cat} />
                  <FormElements type="select" Field={Field} label="Company" name="company" options={com} />

                  <Grid item xs={12} marginY={2}>
                    <AboutSites />
                    <Typography color="text.secondary" align={"left"}>
                      You have the option to select the websites where your advertisement will be shown. Whenever there is a change to the job post, all the selected sites will receive the updated
                      information. Additionally, each website you choose will deduct a credit from your package (except hireimm.com).
                    </Typography>
                    {options.map((opt, i) => (
                      <Typography>
                        <Field name="websites" type="checkbox" value={opt.value} />

                        <HtmlTooltip title={<React.Fragment>{opt.description}</React.Fragment>}>
                          <Button>{opt.label}</Button>
                        </HtmlTooltip>
                      </Typography>

                      //   <FormControlLabel control={<Checkbox defaultChecked />} label={opt.label} value={opt.value} key={i} /> */}
                      //   <ToggleButton value={opt.value} key={i} color="primary" padding={"5px"}>
                      //     {values.websites.includes(opt.value) ? (
                      //       <>
                      //         <CheckIcon />
                      //       </>
                      //     ) : null}{" "}
                      //     {opt.label}
                      //   </ToggleButton>
                      //  </Field>
                    ))}
                  </Grid>

                  <Box margin={1}>
                    <Typography variant={"h5"}>Target Foreign Worker (For FREE)</Typography>
                    <Typography color="text.secondary" align={"left"}>
                      If you select this option, your ad will appear on Hireimm.com for free. Foreign workers will be the target audience for this website.
                    </Typography>
                    <FormControlLabel control={<Field component={Switch} type="checkbox" name="hireimm" color="warning" />} label="Post on HireImm" />
                  </Box>
                  <Box margin={1}>
                    <Typography variant={"h5"}>Post Status</Typography>
                    <Typography color="text.secondary" align={"left"}>
                      The post will not be visible by default, but you can activate it
                    </Typography>
                    <FormControlLabel control={<Field component={Switch} type="checkbox" name="status" color="warning" />} label={values.status ? "Active" : "Deactive"} />
                  </Box>

                  <Grid item container xs={12}>
                    <Typography variant={"subtitle1"}>
                      you have <Typography variant={"h5"}> {Available - jobPosts > 0 ? Available - jobPosts : 0} </Typography> available credits
                    </Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    {Available - jobPosts - values.websites.length >= 0 ? (
                      <Button size={"large"} variant={"contained"} type={"submit"} disabled={!dirty}>
                        Post your job
                      </Button>
                    ) : (
                      <TransferToBuy />
                    )}
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <TransferToBuy />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  ) : (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Skeleton />
          <Skeleton animation="wave" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton />
          <Skeleton animation="wave" />
        </Grid>
      </Grid>
    </Container>
  );
}
