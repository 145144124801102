import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Container from "../../assets/styles/Container";
import { CheckoutSession } from "../../stripe/CheckoutSession";
import LoadingButton from "@mui/lab/LoadingButton";

const mock = [
  {
    title: "Newborn",
    subtitle: "1 job posting",
    price: "$29.99",
    priceId: "price_1Kp5ktCVk5hnoOYceTgjxDHK",
    item: 1,
    features: ["1 job posting"],
    isHighlighted: false,
  },
  {
    title: "Baby Bear",
    subtitle: "( %22 discount )",
    price: "$69.99",
    priceId: "price_1KoEBrCVk5hnoOYcDplnTaN5",
    item: 3,
    features: ["3 Job Posts Included"],
    isHighlighted: true,
  },
  {
    title: "Papa Bear",
    subtitle: "( %33 discount )",
    price: "$99.99",
    priceId: "price_1KoEExCVk5hnoOYcIW5Ltd6Z",
    item: 5,
    features: ["5 Job Posts Included"],
    isHighlighted: true,
  },
  {
    title: "Mama Bear",
    subtitle: "( %55 discount )",
    price: "$336.99",
    priceId: "price_1KoEGTCVk5hnoOYcJA4RU1Qi",
    item: 25,
    features: ["25 Job Posts Included"],
    isHighlighted: true,
  },
  {
    title: "Family Bear",
    subtitle: "( %70 discount )",
    price: "$449.99",
    priceId: "price_1KoEMyCVk5hnoOYcPiZtbMTF",
    item: 50,
    features: ["50 Job Posts Included"],
    isHighlighted: true,
  },
];

export default function Packages() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleUpgrade = (priceId, item) => {
    setLoading(true);
    CheckoutSession({ uid: currentUser.uid, priceId: priceId, item: item });
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "rgb(247, 250, 255)",
          backgroundImage: `linear-gradient(120deg, rgb(255, 255, 255) 0%, rgb(247, 250, 255) 100%)`,
        }}
      >
        <Container position={"relative"} zIndex={3}>
          <Box>
            <Box marginBottom={4}>
              <Typography
                variant="h3"
                gutterBottom
                align={"center"}
                sx={{
                  fontWeight: 900,
                }}
              >
                Flexible pricing options
              </Typography>
              <Typography variant="h6" component="p" color="text.primary" align={"center"}>
                On this website, all advertisements will be displayed for 180 days from the time they are placed.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box component={Card} height={1} display={"flex"} flexDirection={"column"} variant={"outlined"}>
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography variant={"h4"} fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={"text.secondary"}>{item.subtitle}</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"baseline"} marginBottom={2}>
                    <Typography variant={"h3"} fontWeight={700}>
                      {item.price}
                    </Typography>
                    <Typography variant={"subtitle1"} color={"text.secondary"} fontWeight={700}></Typography>
                  </Box>
                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box component={ListItem} disableGutters width={"auto"} padding={0}>
                          <Box component={ListItemAvatar} minWidth={"auto !important"} marginRight={2}>
                            <Box component={Avatar} bgcolor={theme.palette.primary.main} width={20} height={20}>
                              <svg width={12} height={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                              </svg>
                            </Box>
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: "flex-end", padding: 4 }}>
                  <LoadingButton size={"large"} fullWidth variant={"contained"} loading={loading} onClick={() => (currentUser ? handleUpgrade(item.priceId, item.item) : navigate("/signup"))}>
                    {currentUser ? "Upgrade" : "Sign Up"}
                  </LoadingButton>
                </CardActions>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
