/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "../../assets/styles/Container";
const mock = [
  {
    title: "NewComer Needs",
    subtitle: "There is a special job portal designed especially for immigrants looking for work",
    link: "https://newcomerneeds.com",
    icon: (
      <Box
        component={"img"}
        src={"https://firebasestorage.googleapis.com/v0/b/hireimm1759.appspot.com/o/photos%2Flogo.c6fbcbf2fadcfa55f51d.png?alt=media&token=dcb73000-35fb-44ff-99d8-6083b8afa7d8"}
        height={40}
      />
    ),
  },
  {
    title: "Aboriginal Career",
    subtitle: "Aboriginals can apply for jobs that suit their background and skills on this website",
    link: "https://aboriginalcareer.ca",
    icon: (
      <Box
        component={"img"}
        src={"https://firebasestorage.googleapis.com/v0/b/hireimm1759.appspot.com/o/photos%2Flogo.58eb077971bb9fcc300f.png?alt=media&token=9940c284-ece2-4403-b8c4-e3e066d3e933"}
        height={40}
      />
    ),
  },
  {
    title: "Youth Jobs Hub",
    subtitle: "designed to connect Canadian youth with various employment opportunities in the country",
    link: "https://youthjobshub.com",
    icon: (
      <Box
        component={"img"}
        src={"https://firebasestorage.googleapis.com/v0/b/hireimm1759.appspot.com/o/photos%2FYouth%20Jobs%20Hub.png?alt=media&token=8ae26b36-e6fd-4313-b6de-5d633649030e"}
        height={60}
      />
    ),
  },
  {
    title: "Go Job Hunter",
    subtitle: "This website can be used for advertising high-wage jobs on a national basis",
    link: "https://gojobhunter.com",
    icon: (
      <Box component={"img"} src={"https://firebasestorage.googleapis.com/v0/b/hireimm1759.appspot.com/o/photos%2Fdownload.png?alt=media&token=d183372a-c1bd-4f54-935e-99b517fc443d"} height={30} />
    ),
  },
  {
    title: "Hireimm",
    subtitle: "HireImm aims to get foreign skilled workers the jobs they deserve",
    link: "https://hireimm.com",
    icon: (
      <Box
        component={"img"}
        src={"https://firebasestorage.googleapis.com/v0/b/hireimm1759.appspot.com/o/photos%2Fdownload%20(1).png?alt=media&token=c6c1e901-e4e8-4279-8873-a9d6868fb63e"}
        height={40}
      />
    ),
  },
];

export default function AboutSites() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography variant={"h4"} gutterBottom align={"center"} sx={{ fontWeight: 700 }}>
            Post on five different websites
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={4}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Box component={"a"} href={item.link}>
                    <Box
                      component={Avatar}
                      width={"90%"}
                      height={80}
                      marginBottom={2}
                      bgcolor={alpha(theme.palette.primary.main, 0.1)}
                      color={theme.palette.primary.main}
                      variant={"rounded"}
                      borderRadius={2}
                    >
                      {item.icon}
                    </Box>
                  </Box>
                  <Typography variant={"h5"} gutterBottom sx={{ fontWeight: 700 }}>
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">{item.subtitle}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
