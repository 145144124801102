import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { LinearProgress, TextField } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../firebase";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Application = ({ id }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], ["bold", "italic", "underline", "strike", "link"], [{ align: [] }], [{ color: [] }, { background: [] }], ["clean"]],
  };
  const [file, setFile] = useState("");
  const [progress, setProgress] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const [coverLetter, setCoverLetter] = useState("");
  if (!id) {
    id = "general";
  }

  return (
    <Box margin={"0 auto"}>
      <Box
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-multiline": {
            padding: 0,
          },
          "& .MuiOutlinedInput-input": {
            background: theme.palette.background.paper,
            padding: 2,
          },
        }}
      >
        <Formik
          initialValues={{ fileURL: "" }}
          enableReinitialize
          onSubmit={async (values) => {
            values.coverLetter = coverLetter;
            values.jobId = id;
            const fileRef = ref(storage, `applicants/${file.name}-${values.email}`);
            uploadBytes(fileRef, file).then((snapshot) => {
              const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(prog);
              if (prog !== 0) {
                setVisibility(true);
              }
              getDownloadURL(snapshot.ref).then((fileURL) => {
                values.fileURL = fileURL;
                values.job = id;
                addDoc(collection(db, "applications"), values);
              });
            });
          }}
        >
          {({ values, dirty, handleChange }) => {
            return (
              <Form>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12} sm={6} data-aos="fade-up">
                    <TextField label="Full Name" onChange={handleChange} variant="outlined" name="fullName" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} data-aos="fade-up">
                    <TextField onChange={handleChange} label="Email" variant="outlined" name="email" fullWidth required />
                  </Grid>
                  <Grid item xs={12} data-aos="fade-up">
                    <Typography>Cover Letter</Typography>
                    <ReactQuill theme="snow" value={coverLetter} modules={modules} onChange={setCoverLetter} />
                  </Grid>
                  <Grid item xs={12} sm={6} data-aos="fade-up">
                    <Typography>Upload your resume</Typography>
                    <TextField
                      type="file"
                      onChange={(event) => {
                        setFile(event.currentTarget.files[0]);
                      }}
                    />

                    <Box sx={{ width: "63%" }}>
                      <LinearProgress variant="determinate" value={progress} />
                    </Box>
                  </Grid>
                  <Grid item justifyContent="center" xs={12} sm={6} disabled={visibility}>
                    <Typography align={"left"} variant={"caption"} color="text.secondary" component={"p"}>
                      After applying, the employer will receive your resume and information
                    </Typography>
                    <Button variant="contained" type="submit" color="primary">
                      Apply now
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default Application;
