import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function Hit(props) {
  const theme = useTheme();
  return (
    <Box>
      <Grid
        container
        sx={{
          background: theme.palette.background.paper,
          borderRadius: 2,
          margin: 2,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            "&:last-child": {
              borderBottom: 0,
            },
          }}
        >
          <Box padding={2} display={"flex"} alignItems={"center"}>
            <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} flex={"1 1 100%"} justifyContent={{ sm: "space-between" }} alignItems={{ sm: "center" }}>
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle1"} fontWeight={700}>
                  {props.hit.title}
                </Typography>
                {props.hit.category ?? <Typography color={"text.secondary"}>{props.hit.category}</Typography>}
              </Box>
              {props.hit.location && props.hit.location !== "null" ? <Typography color={"text.secondary"}>{props.hit.location}</Typography> : <Typography color={"text.secondary"}>Canada</Typography>}
            </Box>
            <Box marginLeft={2}>
              <Button
                variant="outlined"
                href={props.hit.path}
                color="primary"
                size="small"
                endIcon={
                  <Box component={"svg"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={12} height={12}>
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </Box>
                }
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
