import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Container from "../../assets/styles/Container";
export default function TransferToBuy() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        <Typography
          variant="h4"
          color="text.primary"
          align={"center"}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          There's not enough credit to post an ad
        </Typography>
        <Typography variant="h6" component="p" color="text.secondary" sx={{ fontWeight: 400 }} align={"center"}>
          To post on this site and related ones, you need enough credibility. Visit the package section to buy credit.
        </Typography>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems={{ xs: "stretched", sm: "flex-start" }} justifyContent={"center"} marginTop={4}>
          <Button component={"a"} variant="contained" color="primary" size="large" fullWidth={isMd ? false : true} onClick={() => navigate("/packages")}>
            View pages
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
