import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logo from "../images/logo.png";

const Footer = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={1} flexDirection={{ xs: "column", sm: "row" }}>
          <Box display={"flex"} component="a" href="/" height={30}>
            <Box component={"img"} src={logo} height={40} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography align={"center"} variant={"subtitle2"} color="text.secondary" gutterBottom>
          &copy; 2021, Vancouver. All rights reserved
        </Typography>
        <Typography align={"center"} variant={"caption"} color="text.secondary" component={"p"}>
          When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and
          safer experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
