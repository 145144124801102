import { Field, Formik, Form } from "formik";
import React from "react";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Card, Link, Typography } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { FormElements } from "../../assets/styles/FormElements";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../assets/styles/Container";

let initialValues = {
  email: "",
};
const signUpData = [
  { name: "email", label: "Email", format: "text" },
  { name: "password", label: "Password", format: "password" },
];

export default function SignUpForm() {
  const { Signup } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Container>
      <Helmet>
        <title>Sign up to Go Job Hunter</title>
        <link rel="canonical" href="https://gojobhunter.com/signup" />
      </Helmet>
      <Box bgcolor={"alternate.main"}>
        <Container maxWidth={800}>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: "uppercase",
              }}
              gutterBottom
              color={"text.secondary"}
              fontWeight={700}
            >
              SIGNUP
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              Create an account
            </Typography>
            <Typography color="text.secondary">Fill out the form to get started.</Typography>
          </Box>
          <Card sx={{ p: { xs: 4, md: 6 } }}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={async (values, { resetForm }) => {
                await Signup(values.email, values.password);
                if (location.state?.from) {
                  navigate(location.state.from);
                } else {
                  navigate("/");
                }
                resetForm();
              }}
            >
              {({ dirty }) => (
                <Form>
                  <Box paddingY={{ xs: 2, sm: 1, md: 1 }}>
                    <Grid container spacing={2}>
                      {signUpData.map((data, i) => {
                        return <FormElements type={data.format} Field={Field} label={data.label} name={data.name} options={data.options} />;
                      })}
                      <Grid item container xs={12}>
                        <Button size={"large"} variant={"contained"} type={"submit"} disabled={!dirty}>
                          Sign up
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Typography>
                      Already have an account?{" "}
                      <Link href="/signin" style={{ textDecoration: "none" }}>
                        Login
                      </Link>
                    </Typography>
                  </Box>
                </Form>
              )}
            </Formik>
          </Card>
        </Container>
      </Box>
    </Container>
  );
}
