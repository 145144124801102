import React, { useEffect, useState } from "react";
import Container from "../../assets/styles/Container";
import { Avatar, Box, Card, Grid, Skeleton, Typography } from "@mui/material";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import DashboardCards from "./DashboardCards";
import JobListEdit from "../jobs/JobListEdit";
import { useTheme } from "@emotion/react";
// import { Addcustomers } from "../../stripe/CheckoutSession";

let com = [];
let userJobs = [];

export default function EmployerDashboard() {
  const { currentUser } = useAuth();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const Companies = async () => {
    const docRef = collection(db, "companies");
    const q = query(docRef, where("uid", "==", currentUser.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((company) => {
      com.push({ id: company.id, ...company.data() });
    });
    setLoading(true);
  };
  async function FetchJobs() {
    const docRef = query(collection(db, "jobs"), where("uid", "==", currentUser.uid));
    await getDocs(docRef).then((jobs) => {
      jobs.forEach((job) => {
        userJobs.push({ id: job.id, ...job.data() });
      });
    });
  }
  useEffect(() => {
    Companies();
    FetchJobs();
  }, []);

  return loading ? (
    <>
      <Grid item xs={12}>
        <Box component={Card} padding={2} width={1} height={1}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box component={Avatar} width={50} height={50} marginBottom={2} bgcolor={theme.palette.primary.main} color={theme.palette.background.paper}></Box>
            <Box marginLeft={2}>
              <Typography variant={"h6"} gutterBottom sx={{ fontWeight: 500 }}>
                You are logged in with:
                <Typography color="text.secondary">{currentUser.email}</Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <DashboardCards />
      <JobListEdit />
    </>
  ) : (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3} margin={4}>
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton />
          <Skeleton width="60%" />
        </Grid>
        <Grid item xs={12} sm={6} md={3} margin={4}>
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton />
          <Skeleton width="60%" />
        </Grid>
        <Grid item xs={12} sm={6} md={3} margin={4}>
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton />
          <Skeleton width="60%" />
        </Grid>
      </Grid>
    </Container>
  );
}
