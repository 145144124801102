import { alpha, AppBar, Box, Divider, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Container from "./Container";
import Footer from "./Footer";
import Header from "./Header";
import TawkTo from "tawkto-react";

export default function Layout() {
  const theme = useTheme();
  useEffect(() => {
    var tawk = new TawkTo("608c7aa25eb20e09cf38419b", "1f4ibu32l");

    tawk.onStatusChange((status) => {
      // console.log(status)
    });
  }, []);
  return (
    <Box>
      <AppBar
        position={"flex"}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        }}
        elevation={0}
      >
        <Container maxWidth={0.8} paddingY={{ xs: 1, sm: 1.5 }}>
          <Header />
        </Container>
      </AppBar>

      <Box height="100%" overflow="auto">
        <Outlet />
        <Divider />
      </Box>
      <Container paddingY={4}>
        <Footer />
      </Container>
    </Box>
  );
}
