import firebase from "../firebase";
import getStripe from "./initializeStripe";

export async function CheckoutSession({ uid, priceId, item }) {
  const checkoutSessionRef = await firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      mode: "payment",
      metadata: {
        item: item,
      },
      price: priceId,
      quantity: 1,
      allow_promotion_codes: true,
      success_url: `${window.location.origin}/post`,
      cancel_url: `${window.location.origin}/packages`,
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId, error } = snap.data();
    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    }
    if (error) {
      alert(error.message);
    }
  });
}

// export function Addcustomers() {
//   const Addc = async () => {
//     const stripe = new Stripe("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");
//     await stripe.customers.create({ email: "reza@gmail.com" });
//   };

//   return <button onClick={() => Addc()}>add</button>;
// }
