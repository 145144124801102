import { Field, Formik, Form } from "formik";
import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Card, Link, Typography } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { FormElements } from "../../assets/styles/FormElements";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../assets/styles/Container";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";

let initialValues = {
  email: "",
};
const signinData = [
  { name: "email", label: "Email", format: "text" },
  { name: "password", label: "Password", format: "password" },
];

export default function SignInForm() {
  const { Login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Container>
      <Helmet>
        <title>Sign in to Go Job Hunter</title>
        <link rel="canonical" href="https://gojobhunter.com/signin" />
      </Helmet>
      <Box bgcolor={"alternate.main"}>
        <Container maxWidth={800}>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: "uppercase",
              }}
              gutterBottom
              color={"text.secondary"}
              fontWeight={700}
            >
              Login
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              Welcome back
            </Typography>
            <Typography color="text.secondary">Login to manage your account.</Typography>
          </Box>
          <Card sx={{ p: { xs: 4, md: 6 } }}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={async (values, { resetForm }) => {
                await Login(values.email, values.password);
                if (location.state?.from) {
                  navigate(location.state.from);
                } else {
                  navigate("/");
                }
                resetForm();
              }}
            >
              {({ dirty }) => (
                <Form>
                  <Box paddingY={{ xs: 2, sm: 1, md: 1 }}>
                    <Grid container spacing={2}>
                      {signinData.map((data, i) => {
                        return <FormElements type={data.format} Field={Field} label={data.label} name={data.name} options={data.options} />;
                      })}
                      <Link href="/forgot" style={{ textDecoration: "none", padding: "10px" }}>
                        Forgot your password?
                      </Link>
                      <Grid item container xs={12}>
                        <Button size={"large"} variant={"contained"} type={"submit"} disabled={!dirty}>
                          Sign In
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Typography>
                      Don't have an account yet?{" "}
                      <Link href="/signup" style={{ textDecoration: "none" }}>
                        Sign up here
                      </Link>
                    </Typography>
                  </Box>
                </Form>
              )}
            </Formik>
          </Card>
        </Container>
      </Box>
    </Container>
  );
}
