import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "../../assets/styles/Container";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import { useNavigate } from "react-router-dom";
const mock = [
  {
    title: "Add Company",
    subtitle: "You can add companies to your account or manage them",
    icon: <AddCircleRoundedIcon />,
    link: "/company",
  },
  {
    title: "Post a new job",
    subtitle: "Create a new job posting to recruit talent.",
    icon: <PostAddRoundedIcon />,
    link: "/post",
  },
  {
    title: "Purchase a package",
    subtitle: "You will need to select the package you wish to place the advertisement in",
    icon: <InventoryRoundedIcon />,
    link: "/packages",
  },
];

const DashboardCards = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Container>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} margin={4} key={i}>
            <Box component={Card} padding={4} width={1} height={1} onClick={() => navigate(item.link)} style={{ cursor: "pointer" }}>
              <Box display={"flex"} flexDirection={"column"}>
                <Box component={Avatar} width={50} height={50} marginBottom={2} bgcolor={theme.palette.primary.main} color={theme.palette.background.paper}>
                  {item.icon}
                </Box>
                <Typography variant={"h6"} gutterBottom sx={{ fontWeight: 500 }}>
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DashboardCards;
