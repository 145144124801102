import React, { useEffect, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Container from "../../assets/styles/Container";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
export default function CompaniesList() {
  const { currentUser } = useAuth();
  const [companies, setCompanies] = useState([]);
  const FetchCompanies = () => {
    const docRef = query(collection(db, "companies"), where("uid", "==", currentUser.uid));
    getDocs(docRef).then((companies) => {
      companies.forEach((company) => {
        setCompanies((old) => [...old, { id: company.id, ...company.data() }]);
      });
    });
  };

  useEffect(() => {
    FetchCompanies();
  }, []);
  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "alternate" }}>
            <TableRow>
              <TableCell>
                <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase" }}>
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase" }}>
                  Email
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((item, i) => {
              return (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:nth-of-type(2n)": { bgcolor: "alternate.main" },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant={"subtitle2"} fontWeight={700}>
                      {item.title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={"text.secondary"} variant={"subtitle2"}>
                      {item.email}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
