import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewRoundedIcon from "@mui/icons-material/PowerSettingsNewRounded";
import Tooltip from "@mui/material/Tooltip";
import logo from "../images/logo.png";
import { useAuth } from "../../components/contexts/AuthContext";
import { Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
const mock = [
  {
    title: "Contact Us",
    href: "/contactus",
  },
  {
    title: "About Us",
    href: "/aboutus",
  },
];

export default function Header() {
  const { currentUser, Logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [alert, setAlert] = useState({});
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const FetchAlert = async () => {
    const docRef = doc(db, "alert", process.env.REACT_APP_WEBSITE);
    const alertRef = await getDoc(docRef);
    setAlert(alertRef.data());
  };

  useEffect(() => {
    FetchAlert();
  }, []);
  return (
    <>
      {alert.title ? (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} style={{ borderBottom: "1px solid primary" }} marginBottom={2}>
          <a href={alert.link} style={{ textDecoration: "none" }}>
            <Typography color={"error"}>{alert.title}</Typography>
          </a>
        </Box>
      ) : null}
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={1}>
        <Box display={"flex"} alignItems={"center"} color={"primary.dark"}>
          <Link href="/">
            <img src={logo} height={45} />
          </Link>
        </Box>
        <Box display={"flex"}>
          <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
            {mock.map((item, i) => (
              <Box marginLeft={3} key={i}>
                <Link underline="none" component="a" href={item.href} color="text.primary">
                  {item.title}
                </Link>
              </Box>
            ))}
            {currentUser ? (
              <Box marginLeft={3}>
                <Link underline="none" component="a" href="/dashboard" color="primary" fontWeight={700}>
                  Dashboard
                </Link>
              </Box>
            ) : (
              <Box marginLeft={3}>
                <Link underline="none" component="a" href="/signin" color="primary" fontWeight={700}>
                  Sign In
                </Link>
              </Box>
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }} alignItems={"center"}>
            <React.Fragment>
              <Tooltip title="Menu">
                <IconButton onClick={handleClick} size="small">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    overflow: "visible",
                    mt: 1.5,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {mock.map((item, i) => (
                  <MenuItem key={i}>
                    <Link underline="none" component="a" href={item.href} color="text.primary">
                      {item.title}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
          </Box>
          {/* <Box marginX={{ xs: 2, md: 3 }}>
            <Button variant="contained" color="primary" component="a" href="/post">
              Post Job
            </Button>
          </Box> */}
          {currentUser ? (
            <Box marginX={{ xs: 3, md: 3 }}>
              <Button onClick={() => Logout()}>
                <PowerSettingsNewRoundedIcon color="secondary" />
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}

Header.propTypes = {
  onSidebarOpen: PropTypes.func,
};
