import React, { useContext, useEffect, useState } from "react";
import { ServerTime, auth } from "../../firebase";

import firebase from "../../firebase";
import { toast } from "react-toastify";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function Signup(email, password) {
    const cred = await auth.createUserWithEmailAndPassword(email, password).catch(function (error) {
      var errorCode = error.code;
      switch (errorCode) {
        case "auth/email-already-in-use":
          toast.error("You are already registered!", {});
        case "auth/weak-password":
          toast.error("Please choose a strong password", {});
      }
    });
    const refData = firebase.firestore().collection("users").doc(cred.user.uid);
    await refData.set({ email: email, createdAt: ServerTime, jobPosts: Number(0) });
  }

  async function Login(email, password) {
    try {
      return await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          toast.error("The password you entered is incorrect!");
        case "auth/user-not-found":
          toast.error("This email address is not registered!");
      }
    }
  }

  function Logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    Login,
    Signup,
    Logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
