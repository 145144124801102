import { useField, useFormikContext } from "formik";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { TextField } from "formik-material-ui";

export function FormElements({ type, Field, name, label, options, rows, ...otherProps }) {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  switch (type) {
    case "text":
      return (
        <Grid item xs={12} sm={6}>
          <Field component={TextField} label={label} variant="outlined" name={name} fullWidth />
        </Grid>
      );
    case "password":
      return (
        <Grid item xs={12} sm={6}>
          <Field component={TextField} label={label} variant="outlined" name={name} fullWidth type="password" />
        </Grid>
      );
    case "number":
      return (
        <Grid item xs={12} sm={6}>
          <Field component={TextField} label={label} variant="outlined" name={name} fullWidth type="number" />
        </Grid>
      );
    case "textArea":
      return (
        <Grid item xs={12}>
          <Field component={TextField} label={label} variant="outlined" name={name} fullWidth multiline rows={rows ?? 3} />
        </Grid>
      );
    case "select":
      const handleChange = (evt) => {
        const { value } = evt.target;
        setFieldValue(name, value);
      };
      const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: "outlined",
        fullWidth: true,
        onChange: handleChange,
      };

      return (
        <Grid item xs={12} sm={6}>
          <Field component={TextField} label={label} variant="outlined" name={name} {...configSelect}>
            {options.map((item, i) => {
              return (
                <MenuItem key={i} value={item.name}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>
      );
    case "date":
      const configDateTimePicker = {
        ...field,
        ...otherProps,
        label: label,
        type: "date",
        variant: "outlined",
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      };

      return (
        <Grid item xs={12} sm={6}>
          <Field component={TextField} {...configDateTimePicker} />
        </Grid>
      );
  }
}
